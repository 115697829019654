<template>
  <div class="elv-report-ledger-header">
    <el-tabs v-model="tabActiveName" type="border-card" class="elv-report-ledger-tab" @tab-click="onChangeTab">
      <el-tab-pane name="entity-ledger-journals" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('report.journals')
            }}<span>{{
              entityStore.entityDetail?.journalTotal ? formatNumber(entityStore.entityDetail?.journalTotal) : 0
            }}</span>
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="reports-general-ledger" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('project.nav.trialBalance') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-ledger-account-balance" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('report.accountBalance') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-ledger-chart-of-account" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('project.nav.chartOfCount') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane
        v-if="projectDetail?.plan !== ProjectPermissionLevel.OLD_BASIC"
        name="entity-ledger-auxiliary-code"
        class="elv-report-detail-pane"
      >
        <template #label>
          <p>
            {{ t('report.Auxiliary code') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-ledger-journal-type" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('report.journalType') }}
          </p>
        </template>
      </el-tab-pane>
    </el-tabs>
    <div class="elv-report-ledger-header-operating">
      <template v-if="props.showJournalButton">
        <div
          v-loading="postingLoading"
          :class="{ 'is-disabled': entityStore.entityDetail?.progress.postingStatus === 'DOING' }"
          class="elv-ledger-header-main-right__posting"
          @click="onRecalculateCostBasis"
        >
          <template v-if="entityStore.entityDetail?.progress.postingStatus !== 'DOING'">
            <SvgIcon name="task-add" width="14" height="14" fill="#838D95" />
            <span>{{ t('button.postToLedger') }}</span>
            <el-tooltip
              v-if="
                entityStore.entityDetail?.progress.postingStatus === 'FINISHED' &&
                dayjs(entityStore.entityDetail?.progress.journalDoneTime).isAfter(
                  entityStore.entityDetail?.progress.postingDoneTime
                )
              "
              :show-arrow="true"
              effect="dark"
              placement="top"
              width="150"
              popper-class="elv-journal-posting-tips"
              overlay-classname="elv-report-table-tips"
              :show-after="500"
            >
              <SvgIcon name="Information-Filled" width="14" height="14" fill="#2F63EB" />
              <template #content>{{ t('message.postToLedgerInfo') }}</template>
            </el-tooltip>
          </template>
          <template v-else>
            <img src="@/assets/img/reports/sources-sync-loading.png" alt="" />
            <span>{{ t('progressStatus.postingToLedger') }}</span>
          </template>
        </div>
        <div
          v-if="props.showIntegrations"
          link
          class="elv-ledger-header-main-right__integrations"
          @click="onOpenIntegrationDialog"
        >
          <SvgIcon name="reports-integrations" width="14" height="14" />
          {{ t('common.integrations') }}
        </div>
        <elv-button
          type="primary"
          round
          width="93"
          height="30"
          class="elv-financials-header-main-right__addRule"
          @click="emit('onAddJournal')"
          ><SvgIcon name="source-add" width="14" height="14" class="icon" />{{ t('button.addNewJournal') }}</elv-button
        >
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { formatNumber } from '@/lib/utils'
import TransactionsApi from '@/api/TransactionsApi'
import { ProjectPermissionLevel } from '#/ProjectTypes'
import { useEntityStore } from '@/stores/modules/entity'
import { useGlobalStore } from '@/stores/modules/global/index'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const globalStore = useGlobalStore()
const entityStore = useEntityStore()

const props = defineProps({
  journalsTotal: {
    type: [Number, String],
    default: 0
  },
  showIntegrations: {
    type: Boolean,
    default: false
  },
  showJournalButton: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['onAddJournal', 'onOpenIntegrationDialog'])

const tabActiveName = ref('reports-general-ledger')
const postingLoading = ref(false)

const projectDetail = computed(() => {
  return globalStore.projectDetail(entityStore.entityDetail?.projectId)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const onRecalculateCostBasis = async () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.journal?.posting
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (entityStore.entityDetail?.progress.postingStatus !== 'DOING') {
    try {
      postingLoading.value = true
      await TransactionsApi.journalPosting(entityId.value)
      entityStore.fetchEntityDetail(entityId.value)
      ElMessage.success(t('message.postToLedgerStart'))
    } catch (error: any) {
      console.log(error)
      ElMessage.error(error.message)
    } finally {
      postingLoading.value = false
    }
  }
}
const onChangeTab = (tab: any) => {
  if (
    tab.props.name === 'entity-transactions-journals' &&
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.journal?.viewList
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  router.push({
    name: tab.props.name
  })
}

const onOpenIntegrationDialog = () => {
  emit('onOpenIntegrationDialog')
}

onMounted(() => {
  tabActiveName.value = String(route.name)
})
</script>

<style lang="scss" scoped>
.elv-report-ledger-header {
  position: relative;
}

.elv-report-ledger-tab {
  margin-top: 16px;
}

.elv-report-ledger-header-operating {
  position: absolute;
  top: 0px;
  right: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .elv-ledger-header-main-right__integrations {
    float: right;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px;
    border: 0px;
    height: 32px;
    margin-bottom: 5px;
    border-radius: 2px;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #1e2024;
    cursor: pointer;
    margin-right: 16px;

    svg {
      fill: #838d95;
      margin-right: 6px;
    }

    &:not(.is-disabled):hover,
    &:not(.is-disabled):focus,
    &:not(.is-disabled):active {
      background-color: #fff;
      color: #1343bf;

      svg {
        fill: #1343bf;
      }
    }
  }

  .elv-financials-header-main-right__addRule {
    padding: 0 12px;
    margin-bottom: 7px;

    .icon {
      fill: #fff;
      margin-right: 4px;
    }
  }

  .elv-ledger-header-main-right__posting {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 16px;
    margin-bottom: 5px;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
      display: block;
      animation: loading-rotate 2s linear infinite;
    }

    svg {
      fill: #838d95;
    }

    &.is-disabled {
      cursor: not-allowed;
    }

    &:not(.is-disabled):hover,
    &:not(.is-disabled):focus,
    &:not(.is-disabled):active {
      color: #1343bf;

      svg {
        fill: #1343bf;
      }
    }

    :deep(.el-loading-spinner) {
      top: 130%;
      text-align: left;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}
</style>
